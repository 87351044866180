import React, { useEffect } from "react"
import { navigate } from "gatsby";
import Layout from "../components/layout"

export default function NotFound() {
  useEffect(() => {
    navigate("/kariera"); // redirecting to jobs page
  }, []);
  return (
    <Layout blueMenu={true}>
      <section class="section centered" style={{ marginTop: 200 }}>
        <h1>Page Not Found</h1>
        <p>Oops, we couldn't find this page!</p>
      </section>
    </Layout>
  )
}
